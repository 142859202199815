.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "nimbus-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Link container styles */
.links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Link styles */
.link {
  margin: 10px;
  font-size: 2vw;
  text-decoration: none;
  color: blue;
  transition: color 0.3s ease;
}

.link:hover {
  color: darkblue;
}

/* Responsive font size */
@media (min-width: 768px) {
  .link {
      font-size: 24px;
  }
}



.scrolling-banner {
background-color: black;
color: white;
white-space: nowrap;
overflow: hidden;
box-sizing: border-box;
width: 100%;
}

.banner-content {
display: inline-block;
animation: scroll-right 30s linear infinite;
}

@keyframes scroll-right {
0% { transform: translateX(-50%); }
100% { transform: translateX(0%); }
}

.phrase {
display: inline-block;
font-size: 20px; /* Adjust the size as needed */
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 768px) {
.phrase {
  font-size: 1em; /* Smaller font size for smaller screens */
}
}
/* Style for the main image */
.main-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

/* Style for the text columns */
.text-columns {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.column {
  flex: 1; /* Each column will take up equal space */
  padding: 0 15px; /* Spacing between columns */
  text-align: justify;
}

/* Style for the footer */
.site-footer {
  background-color: black;
  padding: 20px 0;
  text-align: center;
}

.footer-link {
  margin: 0 10px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.footer-link:hover {
  text-decoration: underline;
}
